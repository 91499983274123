import * as React from 'react';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import GlobalStyle from '../styles/globalStyles';
import { StaticImage } from 'gatsby-plugin-image';

import Video from '../assets/videos/video_altamar.mp4';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>La Cámara de Gusinde</title>
      </Helmet>

      <GlobalStyle />
      <Container>
        <Link to="/expedicion">
          <StaticImage
            src="../images/logo.png"
            alt="imagen"
            placeholder="blurred"
          />
        </Link>

        <LogoGobContainer>
          <LogoGobierno>
            <StaticImage src="../images/logo-mcap.png" />
          </LogoGobierno>
          <TextoLeyenda>
            Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y
            las Artes.
            <br /> FONDART Regional Magallanes y la Antártica Chilena 2020.
          </TextoLeyenda>
        </LogoGobContainer>

        <VideoStyled loop autoPlay muted>
          <source src={Video} type="video/mp4" />
        </VideoStyled>
      </Container>
    </>
  );
};

export default IndexPage;

const LogoGobContainer = styled.div`
  position: absolute;
  bottom: 14px;
  left: 14px;
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const TextoLeyenda = styled.p`
  color: white;
  margin: 10px 0 0 0;
  line-height: 1.5;
  font-family: sans-serif;
  background: none;
  font-size: 12px;

  @media (min-width: 960px) {
    bottom: 18px;
    left: 18px;
    font-size: 16px;
  }
`;

const LogoGobierno = styled.div`
  width: 120px;
  min-width: 120px;
  margin: 0 12px 0 0;

  @media (min-width: 960px) {
    width: 150px;
    bottom: 18px;
    left: 18px;
  }
`;

const Container = styled.div`
  background: #333333;

  & > a {
    position: absolute;
    width: 200px;
    top: calc(50% - 100px);
    right: 50px;
    z-index: 1000;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const VideoStyled = styled.video`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
`;
